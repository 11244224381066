import { ApiTree } from "@apicase/services";
import RootService from "@/services/RootService";
import WithAuth from "@/services/WithAuth";
import AuthStrings from "@/services/strings/AuthStrings";

const AuthService = new ApiTree(RootService, [{
	url: "login",
	children: [{
		name: AuthStrings.login,
		url: "",
		method: "POST",
	}, {
		name: AuthStrings.refreshToken,
		url: "",
		method: "PUT",
	}],
}]);

const AuthServiceWithAuth = new ApiTree(WithAuth, [{
	url: "login",
	children: [{
		name: AuthStrings.getUserInfos,
		url: "",
		method: "GET",
	}],
}]);

export { AuthService, AuthServiceWithAuth };
