import Vue from "vue";
import VueRouter, { NavigationGuard, Route } from "vue-router";

import Loading from "@/views/DLoading.vue";

import store from "../store";
import i18n from "../i18n";
import { setTitle, resetTitle } from "../utils/title";

/** @param {import("vue-router").Route} route */
const setFh = (route: Route) => {
	// eslint-disable-next-line eqeqeq
	if (route.meta?.fh == undefined || route.meta?.fh) { // enabled by default
		document.getElementsByTagName("html")[0].classList.add("fh");
	} else {
		document.getElementsByTagName("html")[0].classList.remove("fh");
	}
};

Vue.use(VueRouter);

const router = new VueRouter({
	routes: [
		{
			name: "loading",
			path: "/loading",
			component: Loading,
			props: route => {
				const { redirect } = route.query;
				if (redirect) {
					return { redirect };
				}
				return {};
			},
		},
		{
			name: "login",
			path: "/login",
			component: () => import(/* webpackChunkName: "login-view" */ "../views/LogIn.vue"),
			beforeEnter: (_to, _from, next) => {
				if (store.getters.currentUser || localStorage.getItem("token")) {
					// user may be signed in, redirect to loading with redirect to dashboard
					next({ name: "loading", params: { redirect: "dashboard" } });
				} else {
					next(); // else, no token, so not logged in yet
				}
			},
			children: [
				{
					name: "signUp",
					path: "sign-up",
					component: () => import(/* webpackChunkName: "signin-comp" */ "../components/login/SignUpCard.vue"),
					meta: { title: i18n.t("router.ttSignUp") },
				},
				{
					name: "signIn",
					path: "sign-in",
					component: () => import(/* webpackChunkName: "signup-comp" */ "../components/login/SignInCard.vue"),
					meta: { title: i18n.t("router.ttSignIn") },
				},
			],
		},
		{
			path: "/",
			component: () => import(/* webpackChunkName: "user-layout" */ "../layouts/UserMainLayout.vue"),
			meta: { auth: true },
			children: [
				{
					name: "dashboard",
					path: "dashboard",
					component: () => import(/* webpackChunkName: "dashboard-view" */ "../views/DashBoard.vue"),
					meta: { title: i18n.t("router.ttDashboard"), fh: false },
				},
				{
					name: "storage-content",
					path: "storage/:storageId/content",
					component: () => import(/* webpackChunkName: "storagecontent-view" */ "../views/StorageContent.vue"),
					props: true,
				},
				{
					name: "storage-filling",
					path: "storage/:storageId/content/add",
					component: () => import(/* webpackChunkName: "storagefilling-view" */ "../views/StorageFilling.vue"),
					props: true,
				},
				{
					name: "storage-management",
					path: "settings/storages",
					component: () => import(/* webpackChunkName: "storagemanagement-view" */ "../views/StorageManagement.vue"),
					meta: { fh: false },
				},
				{
					name: "dashboard-widgets-management",
					path: "settings/dashboard-widgets",
					component: () => import(/* webpackChunkName: "widgetmanagement-view" */ "../views/DashboardWidgetsManagement.vue"),
					meta: { fh: false, title: i18n.t("router.dashboardWidgetsSettings") },
				},
			],
		}],
});

/** @param {import("vue-router").Route} to */
const checkLoggedIn: NavigationGuard = (to, _from, next) => {
	let needAuth = false;
	if (to.meta?.auth !== undefined) {
		needAuth = to.meta.auth;
	} else {
		const withAuthMeta = to.matched.find(o => o.meta.auth !== undefined);
		if (withAuthMeta) {
			needAuth = withAuthMeta.meta.auth;
		}
	}

	if (needAuth) {
		console.log("Route need auth");
		if (!store.getters.currentUser) {
			next({ name: "loading" });
		} else {
			next();
		}
	} else {
		next();
	}
};

/** @param {import("vue-router").Route} to */
const setToolbarTitleIfAny: NavigationGuard = (to, from) => {
	if (to.meta?.title) {
		setTitle(to.meta.title);
	} else if (from.name !== to.name) {
		resetTitle();
	}
};

router.beforeEach((to, from, next) => {
	setToolbarTitleIfAny(to, from, next);
	setFh(to);
	checkLoggedIn(to, from, next);
});

export default router;
