import fetch from "@apicase/adapter-fetch";
import EventBus from "@/EventBus";
import i18n from "@/i18n";
import store from "@/store";
import { ApiService } from "@apicase/services";
import AuthWrapper from "@/services/wrappers/AuthWrapper";

const WithAuth = new ApiService({
	adapter: fetch,
	url: process.env.VUE_APP_API_URL,
	credentials: "include",
	hooks: {
		async before({ payload, next }: { payload: any, next: (payload: unknown) => void }) {
			if (payload.anon) {
				next(payload);
				return;
			}
			const token = localStorage.getItem("token");
			const refreshToken = localStorage.getItem("refreshToken");

			if (!refreshToken || !token) {
				console.log("Token and / or refresh token not found");
				window.dispatchEvent(new Event("login-needed"));
				return;
			}
			const newPayload = JSON.parse(JSON.stringify(payload));
			newPayload.headers = {
				Authorization: `Bearer ${token}`,
			};
			next(newPayload);
		},
		async fail({ payload, result, next, retry }: { payload: any, next: (payload: unknown) => void, result: any, retry: (req: Request) => void }) {
			if (result.status === 401) {
				// let's try to refresh the tokens
				const refreshToken = localStorage.getItem("refreshToken");
				if (!refreshToken) {
					console.log("No refresh token found in local storage, need to login");
					store.commit.resetStore();
					EventBus.$emit("snackbar", i18n.t("General.sessionExpired"), 5000, "warning");
					// window.dispatchEvent(new Event("login-needed"));
				} else {
					console.log("Will try to refresh the token");
					const successRefresh = await AuthWrapper.refreshToken();
					if (successRefresh) {
						console.log("Token refreshed, retrying request");
						const newPayload = { ...payload };
						const token = localStorage.getItem("token");
						newPayload.headers.Authorization = `Bearer ${token}`;
						retry(newPayload);
						return;
					}
					console.log("Couldn't refresh the tokens, need to login");
					EventBus.$emit("snackbar", i18n.t("General.sessionExpired"), 5000, "warning");
					window.dispatchEvent(new Event("login-needed"));
					store.commit.resetStore();
				}
			} else if (result.status === 403) { // forbidden
				EventBus.$emit("snackbar", i18n.t("General.forbidden"), 5000, "error");
			} else if (result.status === 409) { // conflict
				// eslint-disable-next-line no-alert
				alert(i18n.t("General.httpConflict"));
			} else if (result.status !== 404) {
				console.warn(result);
				// TODO
				// store.commit(MutationNames.setErrorDialogMessage, JSON.stringify(result));
				// store.commit(MutationNames.setErrorDialogShow, true);
			}
			next(result);
		},
	},
	mode: "cors",
});

export default WithAuth;
