const res: {[name: string]: string} = {
	resetStore: "resetStore",
	MutationNames: "MutationNames",
	setCurrentUser: "setCurrentUser",
	setStorages: "setStorages",
	setStorage: "setStorage",
	setToolbarTitle: "setToolbarTitle",
	setStorageContent: "setStorageContent",
	updateInStorageContent: "updateInStorageContent",
	removeFromStorageContent: "removeFromStorageContent",
	setProductResume: "setProductResume",
	deleteStorage: "deleteStorage",
	setDashboardWidgets: "setDashboardWidgets",
	setDashboardWidget: "setDashboardWidget",
	deleteDashboardStorage: "deleteDashboardStorage",
	setStoragePreview: "setStoragePreview",
	addInStoragePreview: "addInStoragePreview",
	removeFromStoragePreview: "removeFromStoragePreview",
};

export default res;
