import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/i18n/en.json";
import fr from "@/i18n/fr.json";

Vue.use(VueI18n);
export default new VueI18n({
	locale: navigator.language.split("-")[0],
	messages: {
		en, fr,
	},
});
