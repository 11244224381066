import { TranslateResult } from "vue-i18n";
import store from "../store";

const setTitle = (title: string | TranslateResult, applyToWindow: boolean = true, applyToToolbar: boolean = true) => {
	const defTitle = `${title} - FM`;
	if (applyToWindow) {
		window.document.title = defTitle;
	}
	if (applyToToolbar) {
		store.commit.setToolbarTitle(title);
	}
};

const resetTitle = (applyToWindow?: boolean, applyToToolbar?: boolean) => {
	// eslint-disable-next-line no-undef
	setTitle(PRODUCTNAME, applyToWindow, applyToToolbar);
};

export { setTitle, resetTitle };
