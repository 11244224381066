
import { mapGetters } from "vuex";
import AuthWrapper from "../services/wrappers/AuthWrapper";
import Vue from "vue";

export default Vue.extend({
	name: "DLoading",
	props: {
		redirect: {
			type: String,
			default: undefined,
		},
	},
	computed: {
		...mapGetters(["currentUser"]),
	},
	created() {
		if (this.currentUser) {
			this.replace();
		} else if (localStorage.getItem("token")) {
			this.getCurrentUser();
		} else {
			this.$router.replace({ name: "signIn" });
		}
	},
	methods: {
		async getCurrentUser() {
			try {
				await AuthWrapper.getCurrentUser();
				this.replace();
			} catch (e: any) {
				if (e.status === 401) {
					console.log("Need to login");
					this.$router.replace({ name: "signIn" });
				} else {
					console.error(e);
				}
			}
		},
		replace() {
			if (this.redirect) {
				this.$router.replace(this.redirect);
			} else {
				this.$router.replace({ name: "dashboard" });
			}
		},
	},
});
