import fetch from "@apicase/adapter-fetch";
import { ApiService } from "@apicase/services";
import EventBus from "@/EventBus";
import i18n from "@/i18n";

const ApiRoot = new ApiService({
	adapter: fetch,
	url: process.env.VUE_APP_API_URL,
	credentials: "include",
	hooks: {
		async fail({ result, next }: { result: Response, next: (payload: unknown) => void }) {
			if (result.status === 401) { // unauthorized
				window.dispatchEvent(new Event("login-needed"));
			} else if (result.status === 403) { // forbidden
				EventBus.$emit("snackbar", i18n.t("General.forbidden"), 5000, "error");
			} else if (result.status === 409) { // conflict
				// eslint-disable-next-line no-alert
				alert(i18n.t("General.httpConflict"));
			} else if (result.status !== 404) {
				console.warn(result);
				// TODO implement error dialog / handling
				// store.commit(MutationNames.setErrorDialogMessage, JSON.stringify(result));
				// store.commit(MutationNames.setErrorDialogShow, true);
			}
			next(result);
		},
		async before({ payload, next }: { payload: Request, next: (payload: unknown) => void }) {
			console.log(`[REQ] ${payload.url}`);
			next(payload);
		},
	},
	mode: "cors",
});

export default ApiRoot;
