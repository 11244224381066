import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#009688",
				secondary: "#4caf50",
				accent: "#cddc39",
				error: "#f44336",
				warning: "#ffc107",
				info: "#2196f3",
				success: "#8bc34a",
				appBackground: "#eceff1",
				textColor: "#000000",
			},
		},
		options: {
			customProperties: true,
		},
	},
	icons: {
		iconfont: "md",
	},
});
