import Vue from "vue";
import i18n from "@/i18n";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";

import "@/filters";
import "./plugins/dragScroll";
import moment from "moment";

Vue.config.productionTip = false;

moment.locale(navigator.language);

// if ("serviceWorker" in navigator) {
// navigator.serviceWorker.register("/sw.js");
// }

new Vue({
	router: router,
	store: store.original,
	i18n,
	vuetify,
	render: h => h(App),
}).$mount("#app");
