import Vue from "vue";
import moment from "moment";

export const date = (str?: string): string => {
	if (str) {
		return new Date(str).toLocaleDateString();
	}
	return "";
};

export const dateTime = (str?: string): string => {
	if (str) {
		return new Date(str).toLocaleString();
	}
	return "";
};

export const firstLetter = (str?: string): string => {
	if (str && str.length > 1) {
		return str[0];
	}
	return "";
};

export const daysFromNow = (date: string): number => {
	return moment(date).diff(new Date(), "days");
};

Vue.filter("date", date);
Vue.filter("dateTime", dateTime);
Vue.filter("firstLetter", firstLetter);
Vue.filter("daysFromNow", daysFromNow);
