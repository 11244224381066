import { AuthService, AuthServiceWithAuth } from "@/services/AuthService";
import AuthStrings from "@/services/strings/AuthStrings";
import store from "@/store";
import { LoginResultVm, TokenVm } from "@fridgeman/api-types";

const updateAuthAndTokens = (loginResult: LoginResultVm) => {
	console.log("Storing / updating token and refresh token");
	localStorage.setItem("token", loginResult.token);
	localStorage.setItem("refreshToken", loginResult.refreshToken);
	store.commit.setCurrentUser(loginResult.user);
};

const clearToken = () => {
	console.log("Clearing token and last_refresh_token");
	localStorage.removeItem("token");
	localStorage.removeItem("last_token_refresh");
};

export default {
	getCurrentUser: async (): Promise<TokenVm> => {
		const { result, success } = await AuthServiceWithAuth(AuthStrings.getUserInfos).doRequest({});
		const user: TokenVm = result.body;
		if (success) {
			store.commit.setCurrentUser(user);
			return user;
		}
		throw result;
	},
	login: async (email: string, password: string): Promise<LoginResultVm> => {
		const { result, success } = await AuthService(AuthStrings.login).doRequest({
			body: {
				email,
				password,
			},
		});
		const loginRes: LoginResultVm = result.body;
		if (success) {
			updateAuthAndTokens(loginRes);
			return loginRes;
		}
		throw result;
	},
	/**
     * Refresh the current token, if possible.
     * Manage storing of the returned token.
     * Note that the api will not be called if no token is stored.
     */
	refreshToken: async (): Promise<boolean> => {
		const refreshToken = localStorage.getItem("refreshToken");
		const token = localStorage.getItem("token");
		if (!refreshToken) {
			return false;
		}
		const { result, success }: { result: any, success: boolean } = await AuthService(AuthStrings.refreshToken).doSingleRequest({
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: {
				refreshToken,
			},
		});
		if (success) {
			console.log("Refreshed token successfully");
			updateAuthAndTokens(result.body);
			return true;
		} if (result.status === 401) {
			console.log("Can't refresh token, may be expired");
			localStorage.removeItem("token");
			localStorage.removeItem("refreshToken");
			return false;
		}
		throw result;
	},
};

export { clearToken };
