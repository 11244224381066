
import EventBus from "@/EventBus";
import { mapGetters } from "vuex";
import MutationNames from "@/store/MutationsNames";
import Vue from "vue";
import SnackbarAction from "./types/SnackbarAction";

export default Vue.extend({
	name: "App",
	data() {
		return {
			dsnackbar: {
				text: "",
				timeout: 3000,
				show: false,
				color: null as string | null,
				actions: null as SnackbarAction[] | null,
				multiLine: false,
			},
		};
	},
	computed: {
		...mapGetters(["lightTheme"]),
	},
	created() {
		this.$vuetify.theme.dark = !this.lightTheme;
		EventBus.$on("snackbar", this.showSnackbar);
		EventBus.$on("snackbar:hide", this.hideSnackbar);
		EventBus.$on("login-needed", () => {
			this.$store.commit(MutationNames.resetStore);
			this.$router.replace({ name: "login" });
		});
		window.addEventListener("login-needed", this.goToLogin);
	},
	beforeDestroy() {
		window.removeEventListener("login-needed", this.goToLogin);
	},
	methods: {
		showSnackbar(message: string, timeout?: number, color?: string, multiLine?: boolean, actions?: SnackbarAction[]) {
			this.dsnackbar.text = message;
			this.dsnackbar.timeout = timeout ?? 3000;
			this.dsnackbar.show = true;
			this.dsnackbar.color = color ?? null;
			this.dsnackbar.actions = actions || [{ actionText: "Close", action: () => EventBus.$emit("snackbar:hide") }];
			this.dsnackbar.multiLine = multiLine ?? false;
		},
		hideSnackbar() {
			this.dsnackbar.show = false;
		},
		goToLogin() {
			this.$router.replace({ name: "signIn" });
		},
	},
});
