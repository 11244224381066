import { createDirectStore } from "direct-vuex";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import { ContainedProductVm, ContainedProductWithProductVm, DashboardWidgetVm, ProductResumeVm, StorageVm, TokenVm } from "@fridgeman/api-types";
import { TranslateResult } from "vue-i18n";

Vue.use(Vuex);

interface StState {
	settings: { light: boolean, animations: boolean };
	currentUser: TokenVm | undefined;
	storages: { [storageId: string]: StorageVm } | undefined;
	storageContents: { [storageId: string]: { [cpId: string]: ContainedProductVm } };
	storagePreviews: { [storageId: string]: { [cpId: string]: true } }, // contains ids of cpids, with true in value (dummy value;
	productResumes: { [productId: string]: ProductResumeVm };
	toolbarTitle: string | TranslateResult,
	dashboardWidgets: { [widgetId: string]: DashboardWidgetVm } | null;
}

const stateBuilder = (): StState => ({
	settings: {
		light: true,
		animations: true,
	},
	currentUser: undefined as TokenVm | undefined,
	storages: undefined as { [storageId: string]: StorageVm } | undefined,
	storageContents: {} as { [storageId: string]: { [cpId: string]: ContainedProductVm } },
	storagePreviews: {} as { [storageId: string]: { [cpId: string]: true } }, // contains ids of cpids, with true in value (dummy value)
	productResumes: {} as { [productId: string]: ProductResumeVm },
	toolbarTitle: PRODUCTNAME as string,
	dashboardWidgets: null as { [widgetId: string]: DashboardWidgetVm } | null,
});

const {
	store,
	rootActionContext,
	moduleActionContext,
	rootGetterContext,
	moduleGetterContext,
} = createDirectStore({
	plugins: [
		createPersistedState({
			paths: ["settings"],
		}),
	],
	state: stateBuilder(),
	mutations: {
		resetStore (state) {
			Object.assign(state, stateBuilder());
		},
		SET_THEME_LIGHT (state, value: boolean) {
			state.settings.light = value;
		},
		setCurrentUser (state, userInfos: TokenVm) {
			state.currentUser = userInfos;
		},
		setStorages (state, storages: StorageVm[]) {
			state.storages = {};
			for (const storage of storages) {
				Vue.set(state.storages, storage.id, storage);
				Vue.set(state.storageContents, storage.id, {});
				Vue.set(state.storagePreviews, storage.id, {});
			}
		},
		setStorage (state, storage: StorageVm) {
			if (!state.storages) {
				state.storages = {};
			}
			Vue.set<StorageVm>(state.storages, storage.id, storage);
		},
		setToolbarTitle (state, title: string | TranslateResult) {
			state.toolbarTitle = title;
		},
		setStorageContent (state, { storageId, containedProducts }: { storageId: string, containedProducts: ContainedProductWithProductVm[] }) {
			const alreadyAdded: { [productId: string]: true } = {};
			Vue.set(state.storageContents, storageId, {});
			for (const containedProduct of containedProducts) {
				const prodResume = containedProduct.product;
				if (prodResume) {
					if (!alreadyAdded[prodResume.id]) {
						Vue.set(state.productResumes, prodResume.id, prodResume);
						alreadyAdded[prodResume.id] = true;
					}
				} else {
					console.error("No product resume in CP");
				}
				Vue.set<ContainedProductVm>(state.storageContents[storageId], containedProduct.id, { ...containedProduct, product: undefined } as ContainedProductVm);
			}
		},
		updateInStorageContent (state, containedProduct: ContainedProductWithProductVm) {
			const prodResume = containedProduct.product;
			if (!prodResume) {
				console.error("No product resume in CP");
			} else {
				Vue.set(state.storageContents[containedProduct.storageId], containedProduct.id, { ...containedProduct, product: undefined } as ContainedProductVm);
				Vue.set(state.productResumes, prodResume.id, prodResume);
			}
		},
		removeFromStorageContent (state, { storageId, containedProductId }: { storageId: string, containedProductId: string }) {
			Vue.delete(state.storageContents[storageId], containedProductId);
		},
		setProductResume (state, productResume: ProductResumeVm) {
			Vue.set<ProductResumeVm>(state.productResumes, productResume.id, productResume);
		},
		deleteStorage (state, storageId: string) {
			if (state.storages) {
				Vue.delete(state.storages, storageId);
			}
			Vue.delete(state.storageContents, storageId);
		},
		setDashboardWidgets (state, widgets: DashboardWidgetVm[]) {
			state.dashboardWidgets = {};
			for (const widget of widgets) {
				Vue.set(state.dashboardWidgets, widget.id, widget);
			}
		},
		setDashboardWidget (state, widget: DashboardWidgetVm) {
			if (!state.dashboardWidgets) {
				state.dashboardWidgets = {};
			}
			Vue.set<DashboardWidgetVm>(state.dashboardWidgets, widget.id, widget);
		},
		deleteDashboardStorage (state, id: string) {
			if (state.dashboardWidgets) {
				Vue.delete(state.dashboardWidgets, id);
			}
		},
		setStoragePreview (state, cps: ContainedProductWithProductVm[]) {
			for (const cp of cps) {
				Vue.set(state.storageContents[cp.storageId], cp.id, cp);
				Vue.set(state.storagePreviews[cp.storageId], cp.id, true);
				if (!cp.product) {
					console.error("No product in CP");
				} else {
					Vue.set(state.productResumes, cp.product.id, cp.product);
				}
			}
		},
		addInStoragePreview (state, { storageId, cpId }: { storageId: string, cpId: string }) {
			Vue.set(state.storagePreviews[storageId], cpId, true);
		},
		removeFromStoragePreview (state, { storageId, cpId }: { storageId: string, cpId: string }) {
			Vue.delete(state.storagePreviews[storageId], cpId);
		},
	},
	getters: {
		lightTheme: state => state.settings.light,
		currentUser: state => state.currentUser,
		storages: state => state.storages,
		storagesList: state => (state.storages ? Object.values(state.storages) : undefined),
		toolbarTitle: state => state.toolbarTitle,
		storageContents: state => state.storageContents,
		productResumes: state => state.productResumes,
		productResumesList: state => (state.productResumes ? Object.values(state.productResumes) as ProductResumeVm[] : undefined),
		dashboardWidgets: state => state.dashboardWidgets,
		dashboardWidgetsList: state => (state.dashboardWidgets ? (Object.values(state.dashboardWidgets) as DashboardWidgetVm[]).sort((a, b) => a.ordinalNumber - b.ordinalNumber) : null),
		storagePreviews: state => state.storagePreviews,
	},
});

export {
	rootActionContext,
	moduleActionContext,
	rootGetterContext,
	moduleGetterContext,
};

export default store;


// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
declare module "vuex" {
	interface Store<S> {
		direct: AppStore
	}
}
